import React, { useEffect, useState } from 'react';
import MenuItems from '../../../JSON/MenuItems'
import { GiHamburgerMenu } from 'react-icons/gi';
import { Button } from './Button';
import navbarLogo from '../../../Assets/Images/navbarlogo.webp'
import { Link } from 'react-router-dom'
import './CSS/navbar.css';
import { UseResizeWindowFunction } from '../../ReusableComponents/UseResizeWindowFunction';
import { FiShoppingCart } from 'react-icons/fi';
// import Aos from 'aos'
// import "aos/dist/aos.css"
export const Navbar = () => {
  const [scroll, setScroll] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 960;
  const [state, setState] = useState(false);

  const handleClick = () => setState(!state);
  UseResizeWindowFunction(setWidth);
  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 50;
      setScroll(scrollCheck);
    };
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [scroll]);

  // useEffect(() => { Aos.init({ duration: 2000 }) }, [])
  return (
    <div className={`navbar-wrapper ${width > breakpoint ? `${scroll ? "sticky" : ""}` : ''} `}>
      <nav className='navbar-container'>
        <Link to="/"><img className={`navbar-logo ${width > breakpoint ? "navbar-animation" : ""}`} src={navbarLogo} alt='Navbar Logo' /></Link>

        <div className="menu-icon" onClick={handleClick}>
          <GiHamburgerMenu className={state ? 'toggled-class-name' : 'not-toggled-class-name'} />
        </div>
        <div className={state ? 'nav-menu active' : 'nav-menu'}>
          {MenuItems.map((item, index) => {
            return (
              <>
                {
                  item.url.startsWith('http') ? (
                    <a key={index} className={item.cName} href={item.url} target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                  ) : (
                    <Link key={index} className={item.cName} to={item.url}>
                      {item.title}
                    </Link>
                  )
                }
              </>
            )
          })}
          <a className='navbar-link-mobile' target="_blank" rel='norefferer' href="https://storyverse.tebex.io/"><FiShoppingCart style={{ margin: '0 5px' }} /> Shop</a>

        </div>
        <Button>SHOP</Button>
      </nav>
    </div>
  )
}


export default Navbar
