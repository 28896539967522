const StreamerData = [
  {
    streamer: 'Leimenstriker',
    streamerlink: 'leimenstriker'
  },
  {
    streamer: 'ShayneFactorOG',
    streamerlink: 'shaynefactorog'
  }
]


export default StreamerData