import React from 'react'
import loadingScreenSpinner from '../../Assets/Images/navbarlogo.webp'
const LoadingScreen = () => {
  return (
    <div>
      <div className="loading-screen">
        <img src={loadingScreenSpinner} className="loading-screen-spinner" alt="Loading spinner" />
      </div>
    </div>
  )
}

export default LoadingScreen