import React, { useRef, useState } from "react";
import {
  Characterquestions,
  RoleplayQuestions,
  PersonalQuestions,
} from "../../JSON/WhitelistApplication";
import Input from "./Input";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import successImage from "../../Assets/Images/navbarlogo.webp";

const ApplicationFields = () => {
  const formRef = useRef();
  const [successMessage, setSuccessMessage] = useState(false);
  const sendApplication = (e) => {
    e.preventDefault();
    if (confirm("Are you sure you want to submit?")) {
      setSuccessMessage(true);
      // setDisabled(true)
      emailjs
        .sendForm(
          "service_h7abtec",
          "template_itms5vq",
          formRef.current,
          "torGAhxcZvoeaFsmI"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <>
      {successMessage ? (
        <div
          className="Application-success"
          style={{ textAlign: "center", color: "white", height: "100vh" }}>
          <img
            src={successImage}
            className="Success-image"
            alt="Success image"
          />
          <h1>Successfully Sent Application</h1>
          <div className="Take-me-back-button">
            <Link to="/" style={{ color: "white" }}>
              Take me away from here!
            </Link>
          </div>
        </div>
      ) : (
        <div className="Whitelist-application-wrapper">
          <h1 className="Whitelist-title">Whitelist Application</h1>

          <p style={{ color: "white", marginTop: "1em" }}>
            Thanks for considering applying to StoryVerse. As you fill out this
            application, please note that membership to our discord is essential
            to receiving communication from our community. If you haven't
            already done so, please join:{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              target="_blank"
              href="https://discord.gg/8rjVJUBW.">
              our discord
            </a>{" "}
            All of our updated rules are immediately available there to help
            answer the following questions and learn what our community is
            about, so review those before you begin. Submitting this application
            means you agree to abide by the rules and stay up-to-date with
            changes communicated there. <br />
            <br />
            Before starting this application, please be aware of the following:
            <ul style={{ marginTop: "1em" }}>
              <li style={{ marginTop: "0.5em" }}>
                This application is designed to be thought provoking,
                emphasizing the need for the applicant to be meticulous and
                thorough with their responses.{" "}
                <b>
                  Length will not determine our decision on your application.
                </b>{" "}
                If you take your time and focus on
                <b>how</b> you reply and not just <b>what</b> you reply with,
                you are far more likely to see positive results.{" "}
              </li>
              <li style={{ marginTop: "0.5em" }}>
                <b>Do not answer with boilerplate responses.</b> We, as
                management, have spent far too many years reviewing the same
                style of responses, such as: What job you want. “I want to be a
                cop” or “I want to be a criminal” is not a sufficient response.
                It can be part of a response, but it should not be the focal
                point of a response. <b>BE UNIQUE.</b>{" "}
              </li>
              <li style={{ marginTop: "0.5em" }}>
                <b>BE TRUTHFUL.</b> Do not waste your time, or ours, by applying
                and lying about information. We will, eventually, find out the
                truth. It’s far better to be truthful about who you are and what
                you want to do in the server. You’ll likely see better results.{" "}
              </li>
            </ul>
          </p>
          <form onSubmit={sendApplication} ref={formRef}>
            <div className="Applications-container">
              <div className="Personal-questions Applications-container-item">
                <h2 className="Applications-secondary-title">
                  Personal Questions
                </h2>
                {PersonalQuestions.map((x, key) => (
                  <Input {...x} key={key} />
                ))}
              </div>
              <div className="Roleplay-questions Applications-container-item">
                <h2 className="Applications-secondary-title">
                  Roleplay Questions
                </h2>
                {RoleplayQuestions.map((x, key) => (
                  <Input {...x} key={key} />
                ))}
              </div>
              <div className="Character-questions Applications-container-item">
                <h2 className="Applications-secondary-title">
                  Character Questions
                </h2>
                <h4>
                  Please note that these are top priority questions, and we
                  expect detailed, well-developed answers that show us you
                  understand how a person realistically navigates the world
                  based on past experiences.{" "}
                </h4>
                {Characterquestions.map((x, key) => (
                  <Input {...x} key={key} />
                ))}
              </div>
              <button className="contact-send-button">
                <span>Send Application!</span>
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ApplicationFields;
