import React from 'react';
import './CSS/sectionTwo.css';
import Cardsbox from './ReusableComponentsMainpage/Cardsbox'

import { FaFlagCheckered, FaUsers } from 'react-icons/fa'
import { ImEnter } from 'react-icons/im'
import { BsChevronDown } from 'react-icons/bs'
import { HowToJoin, OurGoals, WhoWeAre } from '../../JSON/DropdownInfo';

export const SectionTwo = () => {
  return (
    <div className="section-two-wrapper">
      <div className='section-two-container'>
        <h2 className="section-two-title">ABOUT US</h2>
        <div className="cards">
          <Cardsbox header={WhoWeAre.header} arrow={BsChevronDown} icon={FaUsers} description={WhoWeAre.description} />
          <Cardsbox header={OurGoals.header} arrow={BsChevronDown} icon={FaFlagCheckered} description={OurGoals.description} />
          <Cardsbox header={HowToJoin.header} arrow={BsChevronDown} icon={ImEnter} description={HowToJoin.description} />
        </div>
      </div>
    </div>
  )
}


