export const WhoWeAre = {
  header: 'Who We Are',
  description: `We are a community of likeminded people who pride ourselves on our unique and high level of Roleplaying skills. Within this community we will provide you with countless hours of in-depth and enjoyable roleplay experiences. Within our community we ensured to have a staff team and development team who ensure to make our city different to the rest. `
}


export const OurGoals = {
  header: 'Our Goals',
  description: `Within our server we wish to provide a memorable and unique Roleplay experience that isn’t the same of any other place; with a dynamic server philosophy where every day within RP is different and you don’t know what to expect just that you will get some of your best most notable scenes. Our other goal is to build a community of like-minded people to achieve the first goal of people who put RP first above all else.`
}


export const HowToJoin = {
  header: 'How To Join',
  description: `To join You will need to join the discord (https://discord.gg/svrp). Once you are within the discord on this website you will find a application. You will want to fill it out and show us your character who will be coming into the city. Once your application is filled out you will be put forward to a short interview. This is to just ensure that what you say within your characters story is what you wish to roleplay and to ask a few additional questions.`
}