import React from 'react'

const ReadmoreCard = (props) => {
  return (
    <div className="card-container">
      <div className="card-content">
        <div className="card-description">
          <p className='card-readmore'>{props.description}</p>
        </div>
      </div>
    </div>
  )
}

export default ReadmoreCard