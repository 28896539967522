import React, { useEffect, useRef, useState } from 'react'
import { Navbar } from '../ReusableComponents/Navbar/Navbar'
import './CSS/contact.css'
import { Footer } from '../ReusableComponents/Footer/Footer'
import emailjs from '@emailjs/browser';
import { useLoading } from '../../ContextAPI/LoadingContext';
import LoadingScreen from "../ReusableComponents/LoadingScreen"


const Contact = () => {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const { loading, setLoading } = useLoading();


  const sendEmail = (e) => {
    e.preventDefault()
    setSuccessMessage(true)
    setDisabled(true)
    emailjs.sendForm(
      'service_2vg2vi2',
      'template_i97w8uh',
      form.current,
      'torGAhxcZvoeaFsmI'
    )
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };


  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    },
      1000)

  }, [])
  return (
    <div className='page-wrapper'>
      {loading ?
        <>
          <Navbar />
          <div className="App">
            <div className="contact-container">
              <form className="contact-section" onSubmit={sendEmail} ref={form}>
                <h1 className='contact-header-title'>CONTACT US</h1>
                {successMessage ? <h3 className="contact-success-message">Thank you for emailing us! Someone will soon be in touch</h3> : null}
                <input className='contact-name' type="text" placeholder='Name' name='user_name' required />
                <input className='contact-email' type="text" placeholder='Email' name='user_email' required />
                <textarea className='contact-message' placeholder='Message' style={{ resize: 'none' }} rows='10' name='user_message' required />
                <div className="wrapper">
                  <button className='contact-send-button' disabled={disabled} type="submit"><span>Send Message!</span></button>
                </div>
              </form>
              {/* <div className="social-media">
                <video autoPlay loop muted id='background-video' >
                  <source src={background} />
                </video>
              </div> */}
            </div>
          </div>
          <Footer />

        </>
        :
        <LoadingScreen />
      }
    </div>
  )
}

export default Contact



