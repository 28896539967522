import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import './CSS/sectionThree.css';
import Streamerbox from './ReusableComponentsMainpage/Streamerbox';
import StreamerData from "../../JSON/Streamers";

export const SectionThree = () => {
  return (
    <div className='section-three-wrapper'>
      <h2 className="section-three-title">RECOGNIZED STREAMERS</h2>
      <div className="section-three-container">
        <div className="section-three-sliders">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1400: {
                slidesPerView: 3
              }
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {StreamerData.map((x, key) => {
              return (
                <SwiperSlide key={key}>
                  <Streamerbox {...x} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}