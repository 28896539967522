import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mainpage from './Components/Mainpage/Mainpage';
import Contact from './Components/Contact/Contact';
import Rules from './Components/Rules/Rules';
import './CSS/root.css';
import { LoadingProvider } from './ContextAPI/LoadingContext';
import Applications from './Components/Applications/Applications';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoadingProvider>
        <Routes>
          <Route path='/' element={<Mainpage />} />
          {/* <Route path='/rules' element={<Rules />} /> */}
          <Route path='/applications' element={<Applications />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='*' element={<Mainpage />} />
        </Routes>
      </LoadingProvider>
    </BrowserRouter>
  </React.StrictMode>
);
