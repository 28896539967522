import React, { useState } from 'react'
import { FaReadme } from 'react-icons/fa'
import ReadmoreCard from './ReadmoreCard'

const Cardsbox = props => {
  const [sectionTwoCollapse, setSectionTwoCollapse] = useState(false)
  // const [arrowToggled, setArrowToggled] = useState(false)

  const CollapseFunction = () => {
    setSectionTwoCollapse(!sectionTwoCollapse)
    // setArrowToggled(!arrowToggled)
  }
  return (
    <>
      <div className="test-wrapper">
        <div className="box" onClick={CollapseFunction}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div className="first-layer">
            <props.icon className='section-two-icon' />
            <h2>{props.header}</h2>
            <props.arrow className={sectionTwoCollapse ? 'arrow-up-icon' : 'arrow-down-icon'} />
          </div>
          <div className="box-description-container">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <FaReadme className="box-description" />
            {/* <p className="box-description">Read more</p> */}
          </div>
        </div>
        {/* {sectionTwoCollapse ? <p className="box-read-more">{props.readmore}</p> : ""} */}
        {sectionTwoCollapse ? <ReadmoreCard {...props}/> : ""}
      </div>
    </>
  )
}

export default Cardsbox