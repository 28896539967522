export const PersonalQuestions = [
  {
    typeof: `input`,
    name: `RulesInput`,
    label: `Have you read all of the StoryVerse community rules at this time? If so, please copy and paste the link to our ruleset below. Doing so indicates that you read and understood each of them and will be held accountable for any potential infractions.`,
    values: [{ value: `Yes` }, { value: `No` }],
    id: `P1`,
    type: `radio`,
  },
  {
    typeof: `input`,
    name: `DiscordID`,
    label: `Please provide your Discord ID with correct capitalization.`,
    inputplaceholder: `Your Answer`,
    id: `P2`,
    type: `text`,
  },
  {
    typeof: `input`,
    name: `SteamURL`,
    label: `Please provide your Steam account name with proper capitalization and URL link. To get your URL link, go to https://store.steampowered.com/ and sign in. Then click the arrow in the top right corner next to your account name and click "view profile." Copy that page's link in your browser and put it here. The link should look almost identical to the one in the example but with different numbers.`,
    inputplaceholder: `Your Answer`,
    id: `P3`,
    type: `text`,
  },
  {
    typeof: `input`,
    name: `AgeInput`,
    label: `StoryVerse is an 18+ environment. Are you at least 18 years of age or older?`,
    values: [{ value: `Yes` }, { value: `No` }],
    id: `P4`,
    type: `radio`,
  },
];

export const RoleplayQuestions = [
  {
    typeof: `textarea`,
    name: `RoleplayToYou`,
    label: `What does roleplay mean to you?`,
    inputplaceholder: `Your Answer`,
    id: `R1`,
    type: `text`,
  },
  {
    typeof: `textarea`,
    name: `RoleplayExperience`,
    label: `What experience do you have with roleplay? (Note: new roleplayers are welcome here, but we like to know what everyone’s experiences are and where their expectations may come from. Reminder that this is an experienced RP Server. New to GTA Roleplay does not mean individuals are not fit for an experienced server.)`,
    inputplaceholder: `Your Answer`,
    id: `R2`,
  }
];

export const Characterquestions = [
  {
    typeof: `textarea`,
    label: `Please provide a backstory for your primary character. Some tips: Think about: where they came from, who their family, friends, or associates were, what pivotal events make them the person they are today. We are looking for unique characters that will bring quality RP to the city. Be creative and have fun with it! `,
    inputplaceholder: `Your Answer`,
    name: "BackstoryInput1",
    id: `C1`,
  },
  {
    typeof: `textarea`,
    label: `What do you envision your character's RP and story arc to look like? What is your character going to do when they first fly into the city? What kind of occupations and people would they seek out? How will that arc create value and contribute to our community? (This does not need to be insanely detailed in terms of arc. Some of the best RPers are those with loose arcs. Nevertheless, an arc and purpose is needed to live a life.)`,
    inputplaceholder: `Your Answer`,
    name: "ScenarioInput2",
    id: `C2`,
  },
  {
    typeof: `textarea`,
    label: `What type of RP do you intend to participate in and how do you believe this will positively contribute to StoryVerse’s RP community? Please remember: Job type or Activities are not appropriate responses. Explain the style of RP, the demeanor of your RP and how you believe that helps foster a positive RP environment.`,
    inputplaceholder: `Your Answer`,
    name: "ScenarioInput3",
    id: `C3`,
  },
  {
    typeof: `input`,
    name: `ScenarioInput4`,
    label: `You want to rob a bank with two of your friends. Your friend is a great driver, but he only wants to try to get away in a specific two-seater vehicle. Should you?`,
    values: [
      { value: `A: Use the car and the passengers carry each other` },
      { value: `B: Offer to get in the trunk, which looks like it's big enough for a spare tire` },
      { value: `C: Find a different car to rob the bank with` },
    ],
    inputplaceholder: `Your Answer`,
    id: `C4`,
    type: `radio`,
  },
  {
    typeof: `input`,
    name: `ScenarioInput5`,
    label: `You're watching a stream from another StoryVerse community member, and you see a police character actively gathering evidence against a large criminal organization. You could use some money and some good will, so, next time you go into the city you will?`,
    values: [
      { value: `A: Continue your RP as your character normally would` },
      { value: `B: Let the gang members know what you saw` },
      { value: `C: Look for the police officer so you can give them more intel... for a price` },
      { value: `D: Offer to kill the police officer for a large sum of money`}
    ],
    inputplaceholder: `Your Answer`,
    id: `C5`,
    type: `radio`,
  },
  {
    typeof: `input`,
    name: `ScenarioInput6`,
    label: `You're standing outside of the UWU Cafe far from the door, smoking a cigarette. You hear whispers from people inside the building about a race that's being planned for later tonight starting at the Kortz Center. Should you?`,
    values: [
      { value: `A: Jot down some notes and show up in your new race car` },
      { value: `B: Yell back through the wall and ask if you can join` },
      { value: `C: Ignore the conversation and go about your day` },
      { value: `D: Call your cop friend and let him know what you heard`}
    ],
    inputplaceholder: `Your Answer`,
    id: `C6`,
    type: `radio`,
  },
  {
    typeof: `input`,
    name: `ScenarioInput7`,
    label: `You are in a car chase and you're driving a supercar that is low to the ground. You've exhausted all potential getaway options and you've made a wrong turn landing you on THE BEACH. You should?`,
    values: [
      { value: `A: Drive as quickly and recklessly across the sand as you can, trying to escape` },
      { value: `B: Slow down to a realistic speed for a supercar on sand and buy some time to come up with a modified plan` },
      { value: `C: Speed up and hit a huge beach-side jump to cause some chaos, so you can escape` },
    ],
    inputplaceholder: `Your Answer`,
    id: `C7`,
    type: `radio`,
  },
  {
    typeof: `input`,
    name: `ScenarioInput8`,
    label: `You get into a shootout with a group of buddies. You get shot, but you're pretty sure the adversaries were suspiciously good shots, and you think they were cheating. Before the police and ambulances come, do you?`,
    values: [
      { value: `A: Log out of the server and wait until later to come back` },
      { value: `B: RP out your injuries and open a ticket to let staff know` },
      { value: `C: Yell angrily about the situation while down` },
      { value: `D: End up at the hospital and yell at the alleged individual next time you see them` },
    ],
    inputplaceholder: `Your Answer`,
    id: `C8`,
    type: `radio`,
  },
  {
    typeof: `textarea`,
    name: `ScenarioInput9`,
    label: `An elderly man pulls up to you on his scooter, asks you for directions to the jewelry store, and promptly punches you in the face before you have time to answer. What do you do?`,
    inputplaceholder: `Your Answer`,
    id: `C9`,
  },
  {
    typeof: `textarea`,
    name: `ScenarioInput10`,
    label: `You are in a gang altercation and 4 of your members went down. Now you're trying to get away before the police arrive. You have a 4-door vehicle and lights and sirens have just come around the corner. What do you do?`,
    inputplaceholder: `Your Answer`,
    id: `C10`,
  },
    {
    typeof: `textarea`,
    name: `ScenarioInput11`,
    label: `One common issue in RP servers is allowing tension between RPers to escalate to toxicity. If you were to RP with another community member and felt yourself becoming frustrated by the interactions, how would you approach RP scenes involving them moving forward?`,
    inputplaceholder: `Your Answer`,
    id: `C11`,
  },
];
