import React from "react";
import { FiShoppingCart } from "react-icons/fi";
import './CSS/navbar.css';

const STYLES = [
  'btn--primary',
  'btn--outline',
]

const SIZES = [
  'btn--medium',
  'btn--large',
]

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0]
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]
  return (
    <a href="https://storyverse.tebex.io/" target="_blank" rel="norefferer" className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>
      <FiShoppingCart style={{ margin: '0 5px' }} />{children}
    </a>
  )
}
