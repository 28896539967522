import React, { useState } from 'react'
const Input = (props, { key }) => {
  const [wordLength, setWordLength] = useState(0)

  return (
    <div key={key} style={{ margin: '20px 0', padding: '10px' }}>
      {/* hover background effect */}
      <div className="Label-group">
        <label htmlFor={props.id}>{props.label}</label>
        {props.example ? <label className='Input-example'>{props.example}</label> : null}
      </div>
      {props.type == 'radio' ?
        <div className={`${props.values.length >= 4 ? "Radio-grid" : "Radio-smaller-grid"}`}>
          {props.values.map((prop, key) =>
            <div key={key} className="Radio-values">
              <label htmlFor={key + props.id} className="Radio-label">{prop.value}
                <props.typeof id={key + props.id} type={props.type} name={props.name} value={prop.value} required />
              </label>
            </div>
          )
          }
          <div className="Application-separation-line" />
        </div>
        :
        <>
          {props.typeof == 'select' ?
            <div>
              <select name="" id="" required> {props.options.map(x => <option>{x}</option>)}</select>
            </div>
            :
            <div style={{ margin: '20px 0' }}>
              <props.typeof
                type={props.type}
                placeholder={props.inputplaceholder}
                id={props.id}
                minLength={props.minimumlength}
                name={props.name}
                onChange={(e) => setWordLength(e.target.value.trim().split(' ').length)}
                required />
              {props.minimumlength ? <> <small className={wordLength > 300 ? 'Input-minimum-length-done' : 'Input-minimum-length'}>Must be a minimum of {props.minimumlength} words ({wordLength})</small></> : null}
              <div className="Application-separation-line" />
            </div>
          }
        </>
      }
    </div >
  )
}

export default Input