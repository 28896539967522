import { useEffect, useState } from 'react';
import { Navbar } from '../ReusableComponents/Navbar/Navbar';
import { SectionTwo } from './SectionTwo';
import { SectionOne } from './SectionOne';
import { SectionThree } from './SectionThree';
import { Footer } from '../ReusableComponents/Footer/Footer';
import '../../CSS/app.css'
import { useLoading } from '../../ContextAPI/LoadingContext';
import LoadingScreen from '../ReusableComponents/LoadingScreen';


// Add latest youtube video, view all streamers button
const Mainpage = () => {
  const { loading, setLoading } = useLoading();
  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    },
      1000)

  }, [])
  return (
    <div className='page-wrapper'>
      {loading ?
        <>
          <Navbar />
          <div className="App">
            <SectionOne />
            <SectionTwo />
            <SectionThree />
          </div>
          <Footer />
        </>
        :
        <LoadingScreen />
      }

    </div>
  );
}

export default Mainpage;
