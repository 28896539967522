import React, { useEffect, useState } from 'react'
import './CSS/sectionOne.css'
import PCLOGO from '../../Assets/Images/sv-3000-webp.webp'
import MOBILELOGO from '../../Assets/Images/sv-1500-webp.webp'
import { UseResizeWindowFunction } from '../ReusableComponents/UseResizeWindowFunction'
import Aos from 'aos'
import "aos/dist/aos.css"
export const SectionOne = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const breakpoint = 1100
  UseResizeWindowFunction(setWidth);
  useEffect(() => { Aos.init({ duration: 2000 }) }, [])
  return (
    <div className='section-one-wrapper'>
      <div className='section-one-container' >
        <div className="section-one-image-container" data-aos="fade-up">
          {width > breakpoint ?
            <img src={PCLOGO} className='section-one-background-image' alt="storyVerse Logo" />
            :
            <img src={MOBILELOGO} className='section-one-background-image' alt="storyVerse Logo" />
          }
        </div>

        <div className="section-one-join-buttons">
          <a data-aos="fade-right" href='fivem://connect/15.235.85.32' target="_blank" className="box">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 type="button">Join The FiveM Server</h1>
          </a>
          <a data-aos={width > breakpoint ? "fade-left" : "fade-up"} href='https://discord.gg/svrp' target="_blank" className="box">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 type="button">Join The Discord Server</h1>
          </a>
        </div>
      </div>
    </div>
  )
}

