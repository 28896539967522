const MenuItems = [
  {
    title: 'HOME',
    url: '/',
    cName: 'navbar-link',
  },
  {
    title: 'RULES',
    url: 'https://docs.google.com/document/d/17UyPGTpqhc-PHwXxeAkVDDrBmuIv3c3DewxPUUOVGQ0/edit?usp=sharing',
    cName: 'navbar-link',
  },
  {
    title: 'APPLY',
    url: '/applications',
    cName: 'navbar-link',
  },
  {
    title: 'CONTACT',
    url: '/contact',
    cName: 'navbar-link',
  },
  // {
  //   title: 'SHOP',
  //   url: 'https://storyverse.tebex.io/',
  //   cName: 'navbar-link-mobile',
  // },
]

export default MenuItems