import React, { useEffect } from 'react'
import { useLoading } from '../../ContextAPI/LoadingContext'
import Navbar from '../ReusableComponents/Navbar/Navbar'
import './CSS/Applications.css'
import ApplicationFields from './ApplicationFields'
import LoadingScreen from '../ReusableComponents/LoadingScreen';
const Applications = () => {

  const { loading, setLoading } = useLoading();
  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    },
      1000)

  }, [])


  return (
    <div className='page-wrapper' >
      {loading ? <>
        <Navbar />
        <div className="App">
          {/* <h1 style={{ color: 'white', textAlign: 'center', marginBottom: '5em' }} className="glow">COMING SOON</h1> */}
          <ApplicationFields />

        </div>
      </>
        :
        <LoadingScreen />
      }
    </div >
  )
}

export default Applications


