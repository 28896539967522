import React, { useState } from 'react'
import { UseResizeWindowFunction } from '../../ReusableComponents/UseResizeWindowFunction'

const Streamerbox = props => {
  const [width, setWidth] = useState(window.innerWidth)
  const breakpoint = 1400;


  UseResizeWindowFunction(setWidth)

  return (
    <div className="img-wrapper">
      <img src={`/streamerpics/${props.streamerlink}.webp`} alt={props.streamer} style={width > breakpoint ? { width: '100px', height: '100px' } : { width: '70px', height: '70px' }} />
      <h2>{props.streamer}</h2>
      <div className="button-wrapper">
        <a href={`https://www.twitch.tv/${props.streamerlink}`} className='stream-button' target="_blank">
          <div className="button">
            <span className='button-text'>Visit Stream</span>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Streamerbox