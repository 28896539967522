import React from 'react'
import { FiArrowDown } from 'react-icons/fi'
export const ScrollToTop = () => {
  const scrollTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div className='scroll-to-top-div'>
      <FiArrowDown className='scroll-to-top-icon' onClick={scrollTop} />
    </div>
  )
}
